import styled from 'styled-components';

const MainWrapper = styled.div`
  margin: 16px;
  text-align: -moz-center;

  @media (max-height: 568px) and (max-width: 812px) {
    background: linear-gradient(180deg, #ffffff 0%, #f9f9f9 100%);
  }
`;

const LogoWrapper = styled.div`
  text-align: center;
  padding-top: 37px;
  padding-bottom: 33px;
`;

const List = styled.div`
  text-align: -webkit-center;
`;

const Footer = styled.div`
  height: 18px;
  color: #585858;
  font-family: Quicksand;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  position: fixed;
  bottom: 25px;
  width: 100%;
  margin-left: -16px;

  @media (max-height: 568px) and (max-width: 812px) {
    padding-top: 4rem;
    position: relative;
    width: 100%;
    margin-left: 0;
  }
`;

export { LogoWrapper, MainWrapper, List, Footer };
