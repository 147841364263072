import { Block } from "./components/Block";
import { BlockModel } from "./models";
import { Footer, List, LogoWrapper, MainWrapper } from "./styledElements";
import { v4 as uuidv4 } from "uuid";

const blocks: BlockModel[] = [
  {
    title: "Christophe Gaillard Gallery at Art Basel (OVR)",
    url: "https://galeriegaillard.ovr.arteia.com/",
    image: "/assets/1.jpg",
  },
  {
    title: "NFT sale",
    url: "https://opensea.io/collection/the-nautilus-room-collection",
    image: "/assets/nft2.svg",
  },
  {
    title: "Hélène Delprat Digital Catalogue Raisonné",
    url: "https://helenedelprat.dcr.arteia.com/",
    image: "/assets/2.jpg",
  },
  {
    title: "Christophe Gaillard Galerie",
    url: "https://galeriegaillard.com/en/",
    image: "/assets/3.jpg",
  },
  {
    title: "Arteia Website",
    url: "https://arteia.com/en/",
    image: "/assets/4.jpg",
  },
];

export const Home = () => {
  return (
    <MainWrapper>
      <LogoWrapper>
        <img src="/assets/app-logo.jpg" style={{ width: 150 }} />
      </LogoWrapper>
      <List>
        {blocks.map((block) => {
          return <Block key={uuidv4()} block={block} />;
        })}
      </List>
      <Footer>Powered by Arteïa</Footer>
    </MainWrapper>
  );
};
