import React, { useEffect, useState } from "react";
import { Props } from "./models";
import { Image } from "./styles/index";

export function ImageNotMemoized(props: Props) {
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    setImage(props.imageSrc);
  }, [props.imageSrc]);

  return (
    <Image
      style={{
        height: props.sideSize,
        width: props.sideSizeWidth ? props.sideSizeWidth : props.sideSize,
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        flex: "none",
        borderRadius: 4,
      }}
    ></Image>
  );
}

export const ImageLoader = React.memo(ImageNotMemoized);
