
import styled from "styled-components";

const Image = styled.div`
    background: #f9f9f9;
    &:hover{
        background: #ffffff;
    }
    object-fit: cover;
    img{
        background: #f9f9f9;

        :hover{
            background: #ffffff;
        }
    }
`;


export{
    Image
}