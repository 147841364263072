import styled from 'styled-components';

const BlockWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.1);
  display: flex;
  margin-bottom: 8px;
  max-width: 700px;
  max-height: 75px;
  overflow: hidden;
  &:hover {
    box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.3);
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 75px;
`;

const Title = styled.div`
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 15px;
  color: #010101;
  font-family: Quicksand;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  flex: 1;
  align-self: center;
  text-align: left;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @media (max-width: 320px) {
    font-size: 13px;
    padding-left: 10px;
  }
`;

const ArrowWrapper = styled.div`
  text-align: center;
  align-self: center;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
`;

export { BlockWrapper, ImageWrapper, Title, ArrowWrapper };
