import { ImageLoader } from "../../../../components/image-loader";
import { Props } from "./models";
import {
  ArrowWrapper,
  BlockWrapper,
  ImageWrapper,
  Title,
} from "./styledElements";
import { ReactComponent as ArrowRight } from "./icons/simple-arrow-right.svg";

export const Block = (props: Props) => {
  return (
    <BlockWrapper
      onClick={() => props.block.url && window.open(props.block.url, "_blank")}
    >
      {props.block.image && (
        <ImageWrapper>
          <ImageLoader
            imageSrc={props.block.image}
            sideSize={75}
            sideSizeWidth={75}
          />
        </ImageWrapper>
      )}
      <Title>{props.block.title}</Title>
      <ArrowWrapper>
        <ArrowRight />
      </ArrowWrapper>
    </BlockWrapper>
  );
};
